<template>
<div class="mx-auto">
        <div>
            <Loading :isLoading= "this.isLoading" />
            <div>
                <left>
                    <div class="flex-1 bg-gray-0 px-0 py-1 m-1">
                        <div class="row">
                            <center>
                                <p class="lg:text-3xl lg:text-3xl lg:text-3xl lg:text-4xl xl:text-5xl title" style="font-weight: bold; margin-top:40px;">Generar Referencias</p>
                            </center>
                        </div>
                    </div>
                    <div class="flex-1 bg-gray-0 px-0 py-1 m-1">
                        <div class="row">
                            <center>
                                <p class="md:text-xl lg:text-1xl lg:text-2xl lg:text-3xl xl:text-4xl" style="color: #6E6E6E;">
                                    
                                </p>
                            </center>
                        </div>
                    </div>
                    
                    <!-- <div class="flex-1 bg-gray-0 px-0 py-0 m-0"> -->
                    <div>
                        <form @submit="enviarMensaje" class="grid grid-cols-12">
                            <div class="col-span-12 sm:col-span-6" style="padding-left: 5%;">
                                <p class="label-input" style="font-size: 20px; margin-bottom:4px;">Seleccionar rol</p>
                                <div class="form-group">

                                    <select v-model="roleName" id="references_select_rol_generate" class="shadow-md border-2 rounded-lg py-3 w-full px-3" required>
                                        <option v-for="(rol, index) in listRols" :id="'references_option_'+rol.name+'_generate'" :key="index" v-bind:value="rol.name">{{ rol.name_role }}</option>
                                    </select>
                                </div>
                                <br>
                                <center>
                                    <button class="next_button" type="button, text-decoration: none;" id="Button_SendEmail" @click="createReference()">Generar Referencia</button>
                                </center>
                            </div>
                            <div class="col-span-12 sm:col-span-6" style="padding-left: 5%;">
                                    <!-- <p style="font-size: 20px; ">Referencia:</p> -->
                                    <p class="label-input"><strong class="label-input" id="referencia" style=" font-size:25px;">Referencia: {{ reference }}</strong></p>
                                    <button @click="copyUrl()" class="next_button" type="button, text-decoration: none; width:150px" id="copiar" >Copiar URL</button>
                                    <br><br>
                                    <p class="label-input" style="font-size: 20px; margin-bottom:4px;">Ingrese correo para enviar referencia</p>
                                    <input type="email" v-model="email" id="inputCorreo" minlength="10" class="shadow-md border-2 rounded-lg py-3 w-full px-3" required="required" pattern="^[_a-z0-9-]+(.[_a-z0-9-]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,4})$" >
                                    <center>
                                </center>
                            </div>
                            <br>
                            
                            <div class="row">
                                <center>
                                </center>
                            </div>
                            <br>
                            <br>

                            <div class="col-span-12">
                                <center>
                                    <button class="btn-lg next_button" id="Button_CopyCode" style="height:40px;width:240px; background-color:#fdce00" type="submit, text-decoration:none;border-color:#fdce00;background:#fdce00;">
                                        Enviar Referencia
                                    </button>
                                </center>
                            </div>
                        </form>
                        <br>
                    </div>
                </left>
            </div>
        </div>
    <!--<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">-->
    <!--<link href="../../node_modules/tailwindcss/dist/tailwind.min.css" rel="stylesheet"> -->
</div>
</template>



<script>
import('@/assets/css/stylesheet.css')

import MenuLateral from '@/components/LateralMenu.vue';
import Loading from '@/components/Loading/VueLoading.vue'
import Rol from "@/classes/Rol";

export default {
    data() {
      return {
          errors: [],
          apiToken: null,
          currentPassword: "",
          newPassword: "",
          confirmNewPassword: "",
          currentPasswordHide: false,
          newPasswordHide: false,
          confirmNewPasswordHide: false,
          multiApi: process.env.VUE_APP_MULTIAPI,
          business: process.env.VUE_APP_BUSINESS_FONCABSA,
          email: "",
          title_email: "",
          tokenMessage: "",
          reference: "0000000000000000",
          isLoading: false,
          url: process.env.VUE_APP_URLWEB + "/registro?referencia=",
          obj_response: "",
          ccapi: process.env.VUE_APP_CCAPI,
          roleName: "",
          objectRol: null,
          listRols: []
        };
      },
    name: 'CambiarContraseña',
    components: {
        Loading,
        MenuLateral
    },
    props: {
        msg: String
    },
    async mounted(){
        await this.loadInfo();
        await this.getListRols();
    },
    beforeMount() {
    },
    methods:{
        Logout(){
            sessionStorage.clear();
            this.$router.push({path:"/"});
            // window.location.href = "/"
        },
        createReference(){
            if(this.roleName == ""){return;}

            var swal = require('sweetalert2')
            this.reference = null
            this.isLoading = true
            this.title_email = this.changeTittleMessage(this.roleName)
            this.url = process.env.VUE_APP_URLWEB + this.getRegistrationPage(this.roleName)
            var peticion = true;
            var user = sessionStorage.getItem('login')
            if (peticion == true) {
                this.axios.post(this.ccapi + `/${process.env.VUE_APP_BUSINESS_FONCABSA}/generate_references`, {
                    token_auth: user,
                    type_reference: this.roleName
                },
                {
                    'Content-Type': 'application/json'
                })
                .then(response => {
                    this.isLoading = false
                    this.reference = response.data.response.code_reference
                    this.url = this.url+response.data.response.code_reference
                }).catch(error => {
                    this.isLoading = false
                    this.errors = error.response.data.errors
                    swal.fire({
                        title: "Aviso",
                        text: this.errors,
                        icon: "error",
                        confirmButtonColor: '#FEB72B',
                    })
                });
            } else {
                this.isLoading = false
                swal.fire({
                    title: "Aviso",
                    text: "Error al crear referencia contacte con soporte",
                    icon: "error",
                    confirmButtonColor: '#FEB72B',
                })
            }
        },
        cleanControls() {
            this.email = "";
            this.reference = "0000000000000000";
        },
        changeTittleMessage(type_client){
            var title_email = ""
            switch (type_client) {
                case 'cabsa_member':
                case 'member':
                    title_email = "Invitación registro cuenta FONCABSA"
                    break;
                case 'member_analyst':
                case 'administrator':
                case 'loan_analyst':
                case 'enroller':
                case 'supervisor':
                case 'financial_advisor':
                    title_email = "Acceso para crear usuario en FONCABSA"
                    break;
                default:
                    title_email = ""
                    break;
            }
            return title_email
        },
        async enviarMensaje(e){
            e.preventDefault();
            var swal = require('sweetalert2')
            this.isLoading = true
            this.encriptarMensaje()

            if (this.reference != "0000000000000000") {
                if (this.email != "") {
                    await this.axios.post(this.ccapi + `/${process.env.VUE_APP_BUSINESS_FONCABSA}/send_mail`, {
                        business:  "FONCABSA",
                        mail:{
                            business:  "FONCABSA",
                            process_type: "referencia usuario administrador",
                            title: this.title_email,
                            body_mail: this.tokenMessage, 
                            mailing_list: [this.email.toLowerCase()],
                            attachment_list: []
                        }
                    },
                    {
                        'Content-Type': 'application/json'
                    })
                    .then(response => {
                        this.isLoading = false
                        this.cleanControls();
                        var info = "Se envió la referencia correctamente"
                        swal.fire({
                            title: "Aviso",
                            text: info,
                            icon: "success",
                            confirmButtonColor: '#FEB72B',
                        })
                    }).catch(error => {
                        this.isLoading = false
                        swal.fire({
                            title: "Aviso",
                            text: "Ocurrio un error al enviar el correo",
                            icon: "error",
                            confirmButtonColor: '#FEB72B',
                        })
                    });
                } else {
                    this.isLoading = false
                    swal.fire({
                        title: "Aviso",
                        text: "Debe ingresar un correo para el envío",
                        icon: "warning",
                        confirmButtonColor: '#FEB72B',
                    })
                }
            } else {
                this.isLoading = false
                swal.fire({
                    title: "Aviso",
                    text: "No se ha creado una referencia para hacer el envío",
                    icon: "error",
                    confirmButtonColor: '#FEB72B',
                })
            }
        },
        encriptarMensaje(){
            const header = { "alg": "HS256", "typ": "JWT" }
            const encodedHeader = Buffer.from(JSON.stringify(header)).toString('base64')

            var data = {
                "html": this.bodyEmailReference()
            }
            const encodedPayload = Buffer.from(JSON.stringify(data)).toString('base64')
            var jwtSecret = process.env.VUE_APP_JWT_SECRET;
            const crypto = require('crypto')
            const signature = crypto.createHmac('sha256', jwtSecret).update(encodedHeader + '.' + encodedPayload).digest('base64')      
            this.tokenMessage = encodedHeader+"."+encodedPayload+"."+signature
        },
        async loadInfo(){
            this.apiToken =sessionStorage.getItem('login');
        },
        copyUrl(){
            var swal = require('sweetalert2')
            if (this.reference != "0000000000000000") {
                var aux = document.createElement("input");
                aux.setAttribute("value", this.url);
                document.body.appendChild(aux);
                aux.select();
                document.execCommand("copy");
                document.body.removeChild(aux);

                swal.fire({
                    title: "Aviso",
                    text: "URL Copiada.",
                    icon: "success",
                    confirmButtonColor: '#FEB72B',
                })
            } else {
                swal.fire({
                    title: "Aviso",
                    text: "No ha creado una referencia.",
                    icon: "warning",
                    confirmButtonColor: '#FEB72B',
                })
            }
        },
        bodyEmailReference(){
            var bodyEmail = "<!DOCTYPE html> " +
                "<html lang='en'>" +
                    "<head>" +
                        "<meta charset='UTF-8'>" +
                        "<meta name='viewport' content='width=device-width, initial-scale=1.0'>" +
                        "<title>Document</title>" +
                    "</head>" +
                "<body>" +
                    "<div style='background:#810042;height: 130px;display: flex;width: 100%;margin:0 auto;'>" +
                        "<div class='div_centrado' style='margin:0 auto;background:white;width:77%;height:640px;margin-top:50px;border-radius:14px; text-align: center;box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);'>" +
                            "<p style='margin-top:40px;font-family:Roboto,Arial;font-size:22px;color:#6E6E6E;font-weight:bold;'>" +
                                this.title_email +
                            "</p>" +
                            
                            "<p style='margin-top:40px;font-family:Roboto,Arial;color:#8e8e8e;padding-left: 60px;padding-right: 60px;text-align: justify;'>" +
                                "¡Hola!. te queremos hacer la cordial invitación para que te registres en nuestra pagina haciendo click en el enlace siguinte."+
                                "<br>"+
                                "<br>"+
                                "<a href='"+this.url+"' target='_blank'>Click aqui para registrarte!</a>" +
                            "</p>" +
                            "<p style='margin-top: 70px;font-family:Roboto,Arial;color:#8e8e8e;padding-left: 60px;padding-right: 60px;text-align: justify;'>" +
                                "Para cualquier pregunta reenvíe este mensaje, estaremos dispuestos a ayudarle."+
                            "</p>"+
                            "<p style='font-family:Roboto,Arial;color:#8e8e8e;padding-left: 60px;padding-right: 60px;text-align: justify;'>" +
                                "Saludos." +
                                "<br>" +
                                "El equipo de FONCABSA." +
                            "</p>" +
                            "<hr style='width: 89%;margin-top: 40px; border: 1px solid #810042;'>"+
                            "<p style='margin-top: 40px;font-family:Roboto,Arial;color:#403F3F;padding-left: 60px;padding-right: 60px;text-align: justify; font-size: 13px;'> "+
                                "Si este email ha sido enviado a bandeja de correos no deseados o spam, debe enviarlo a su bandeja de entrada, de lo contrario no funcionara el link adjunto** "+
                            "</p>" +
                            "<p style='font-family:Roboto,Arial;color:#8e8e8e;padding-left: 60px;padding-right: 60px;text-align: center;margin-top:40px;'>"+
                                "FONCABSA le ha enviado este mensaje"+
                                "<br>"+
                                "<a href='https://foncabsa.com/' style='text-decoration:none;'>FONCABSA</a>"+
                            "</p>"+
                        "</div>"+
                    "</div>"+
                    "<div style='background:#fcfcfc;height: 570px;display: flex;justify-content: center; width: 50%;margin:0 auto;'>"+
                    
                    "</div>"+
                "</body>"+
            "</html>"
            return bodyEmail
        },
        getRegistrationPage(type){
            switch (type) {
                case "member":
                case "cabsa_member":
                    return "/registro?reference="
                case 'member_analyst':
                case 'administrator':
                case 'loan_analyst':
                case 'enroller':
                case 'supervisor':
                case 'financial_advisor':
                    return "/personal/registro?reference="
                default:
                    throw "No es un rol valido";
            }
        },
        async getListRols(){
            this.objectRol = new Rol();
            this.objectRol.getListActive().then((resp)=>{
                this.listRols = resp.data.response.data
            });
        }
    }
}

</script>

<style scoped>
#panel{
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    #Button_SendEmail{
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        background-color: #bfd243;
        width: 100%;
    }
    #copiar{
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        background-color: #FEB72B;
        width: 150px;
        height: 30px;
        padding-top: 0%;
    }
    #enviar{
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        background-color: #FEB72B;
        width: 290px;
        height: 30px;
        padding-top: 0%;
    }

    #Button_SendEmail{
        white-space: normal;
        border: inherit;
        text-decoration:none;
        border-color:#fdce00;
        background:#fdce00;
    }
    #enviar{
        white-space: normal;
        border: inherit;
    }
    #copiar{
        white-space: normal;
        border: inherit;
        text-decoration:none;
        border-color:#fdce00;
        background:#fdce00;
    }

input[type="email"]:focus-visible{
  border: 2px solid #810042 ;
  outline: none;
}
</style>
