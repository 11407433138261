import api from '@/api/api'
const host = process.env.VUE_APP_CCAPI

export default class Rol {
    
    getListActive = async () => {
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/catalog_rols/get_list_active`;
        const apiUrl = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = {
            token_auth: sessionStorage.getItem("login")
        };
        
        try {
            return await api.httpGet(apiUrl, config, params); 
        } catch (error) {
            throw error.response.data.mensaje;
        }
    }
}